import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './routes/Home';
import Product from './routes/Product';
import Contact from './routes/Contact';
import ErrorPage from './routes/ErrorPage';




function App() {
  return (
   
      <Router>
      <Routes >


<Route path ="/" element={<Home />}  />
<Route path ="/product" element={<Product />}  />
<Route path ="/contact" element={<Contact />}  />
<Route path ="*" element={<ErrorPage />}  />

  
      </Routes>
      </Router>
   
  );
}

export default App;
