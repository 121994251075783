import React from 'react'
import './ProductContentStyles.css'

import { Link } from 'react-router-dom'

import Astronaut from '../assets/moon.jpg'
import Mars from '../assets/pod.jpg'

const ProductContent = () => {
  return (
    <div className='product'>
        <div className='left'>
 <h1>Product</h1>
    <p>Products to survive the streetz</p>
    <Link to='/contact'><button className='btn'>Buy Now</button></Link>
        </div>

<div className='right'>
    <div className='img-container'>
        <div className='image-stack top'>
            <img src={Astronaut} className='img' alt='' />
        </div>
        <div className='image-stack bottom'>
            <img src={Mars} className='img' alt='' />
        </div>
    </div>
</div>


    </div>
  )
}

export default ProductContent