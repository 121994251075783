import React from 'react'

import HeroImage from '../components/HeroImage'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Form from '../components/ContactContent'


const Contact = () => {
  return (
    <div>
<Navbar />
<HeroImage heading='CONTACT' text='connect with us' />
<Form />
<Footer />




    </div>
  )
}

export default Contact