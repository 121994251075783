import React from 'react'
import './CardContainerStyles.css'
import { Link } from 'react-router-dom'

const CardContainer = () => {
  return (
    <>
 <div className='services'>
        <div className='card-container'>
            <div className='card'>
                <h3>Patios and Driveways</h3>
                <span className='bar'></span>
                
                <p>Interlocking Brick</p>
                <Link to='/contact' className='btn'>Book</Link>
            </div>
            <div className='card'>
                <h3>Retaining Walls</h3>
                <span className='bar'></span>
                
                <p>Interlocking Brick</p>
                <Link to='/contact' className='btn'>Book</Link>
            </div>
            <div className='card'>
                <h3>Steps</h3>
                <span className='bar'></span>
                
                <p>Interlocking Brick</p>
                <Link to='/contact' className='btn'>Book</Link>
            </div>
            <div className='card'>
                <h3>Fences</h3>
                <span className='bar'></span>
                
                <p>Wooden, Synthetic, etc</p>
                <Link to='/contact' className='btn'>Book</Link>
            </div>
            <div className='card'>
                <h3>Decks</h3>
                <span className='bar'></span>
                
                <p>Wooden, Synthetic, etc</p>
                <Link to='/contact' className='btn'>Book</Link>
            </div>
            <div className='card'>
                <h3>Landscape Lighting</h3>
                <span className='bar'></span>
                
                <p>Smart Control Lighting</p>
                <Link to='/contact' className='btn'>Book</Link>
            </div>
           
        </div>
    </div>

    </>
  )
}

export default CardContainer