import React from 'react'
import { Link } from 'react-router-dom'
import './VideoStyles.css'
import MatrixVideo from '../assets/matrix_hero.mp4'


const Video = () => {
  return (
    <div className='hero'>

        <video autoPlay loop muted id='video'>
            <source src={MatrixVideo} type='video/mp4' />
        </video>
    <div className='content'>
        <h1>Product</h1>
        <p>of the streetz</p>
        <div>

<Link to='/product' className='btn'>Product</Link>
<Link to='/contact' className='btn btn-light'>Contact</Link>

</div>
    </div>
    </div>
  )
}

export default Video