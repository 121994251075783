import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import HeroImage from '../components/HeroImage'
import ProductContent from '../components/ProductContent'
import CardContainer from '../components/CardContainer'




// import Astronaut from '../assets/moon.jpg'
// import Mars from '../assets/pod.jpg'

const Product = () => {
  return (
    <div>
<Navbar />

<HeroImage heading='PRODUCT' text='of the streetz' />

<CardContainer />
<ProductContent />
<Footer />
    </div>
    



  )
}

export default Product