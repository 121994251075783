import React from 'react'

import HeroImage from '../components/HeroImage'
import Navbar from '../components/Navbar'


const ErrorPage = () => {
  return (
    <div>
<Navbar />
<HeroImage heading='Page Not Found' text='Sorry Try Your Luck Again.' />






    </div>
  )
}

export default ErrorPage